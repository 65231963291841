import { React, useState, useEffect } from "react";
import { Outlet, useParams,Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useCheckbox, useInput, useRadio } from "./hooks";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { fontSize } from "@mui/system";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { useInView } from "react-intersection-observer";

const Input = styled('input')({
  display: 'none',
});

export default function ConcertPrAddInput() {
  let {id} = useParams();
  if(!id){
    id = 'new';
  }

  const { data,dataMy,myInfo,dataConcert,dataConcertPr,setDataConcertPr,dataPlayerParts,getConcert,getConcertPlayerParts,postConcertImage } = useMyData();

  const [tab_itemProps] = useRadio("jp"); // タブ

  const [sdateProps] = useInput(dataConcertPr.sdate||""); // 公演日・曜日
  const [venueProps] = useInput(dataConcertPr.venue || ""); // 会場

  const [publish_forProps] = useRadio(dataConcertPr.publish_for||"2"); // 掲載希望
  const [publish_paperProps] = useRadio(dataConcertPr.publish_paper||""); // 紙面への掲載希望
  const [open_timeProps] = useInput(dataConcertPr.open_time||""); // 開場時間
  const [start_timeProps] = useInput(dataConcertPr.start_time||""); // 開演時間
  const [end_timeProps] = useInput(dataConcertPr.end_time||""); // 終演時間
  const [title_jaProps] = useInput(dataConcertPr.title_ja||""); // 公演名(日本語)
  const [title_enProps] = useInput(dataConcertPr.title_en||"",'english'); // 公演名(英語)
  const [ticket_typeProps] = useRadio(dataConcertPr.ticket_type||""); // チケット種別
  const [ticket_type_text_jaProps] = useInput(dataConcertPr.ticket_type_text_ja||""); // チケット種別（自由入力）(日本語)
  const [ticket_type_text_enProps] = useInput(dataConcertPr.ticket_type_text_en||""); // チケット種別（自由入力）(英語)
  const [chargeProps] = useRadio(dataConcertPr.charge||""); // 入場料金
  const [ticket_saleProps] = useRadio(dataConcertPr.ticket_sale||""); // チケット販売方法
  const [ticket_sale_text_jaProps] = useInput(dataConcertPr.ticket_sale_text_ja||""); // チケット販売方法（自由入力）(日本語)
  const [ticket_sale_text_enProps] = useInput(dataConcertPr.ticket_sale_text_en||""); // チケット販売方法（自由入力）(英語)
  const [ticket_sale_dateProps] = useInput(dataConcertPr.ticket_sale_date||""); // チケット販売・受付開始日(一般)
  const [children_enterProps] = useRadio(dataConcertPr.children_enter||""); // 未就学児の入場
  const [url_jaProps] = useInput(dataConcertPr.url_ja||""); // ホームページURL(日本語)
  const [url_enProps] = useInput(dataConcertPr.url_en||""); // ホームページURL(英語)
  const [commentProps] = useInput(dataConcertPr.comment||""); // その他（事前申込制や整理番号を配布する場合、入力しきれなかった席種情報がある場合は、こちらの欄にご入力ください。（最大100文字））
  const [contact_jaProps] = useInput(dataConcertPr.contact_ja||""); // 問合せ先(日本語)
  const [contact_enProps] = useInput(dataConcertPr.contact_en||""); // 問合せ先(英語)
  const [telProps] = useInput(dataConcertPr.tel||"","number"); // 問合せ先電話番号
  const [telaProps] = useInput(dataConcertPr.tela||"","number"); // 問合せ先電話番号a
  const [telbProps] = useInput(dataConcertPr.telb||"","number"); // 問合せ先電話番号b
  const [telcProps] = useInput(dataConcertPr.telc||"","number"); // 問合せ先電話番号c
  const [emailProps] = useInput(dataConcertPr.email||""); // 問合せ先メールアドレス
  const [email1Props] = useInput(dataConcertPr.email1||""); // 問合せ先メールアドレス
  const [email2Props] = useInput(dataConcertPr.email2||""); // 問合せ先メールアドレス

  const [n_nameProps,,setN_nameProps] = useInput(dataConcertPr.n_name||""); // 入力者団体名
  const [n_l_nameProps,,setN_l_nameProps] = useInput(dataConcertPr.n_l_name||""); // 入力者代表者姓
  const [n_f_nameProps,,setN_f_nameProps] = useInput(dataConcertPr.n_f_name||""); // 入力者代表者名
  const [n_emailProps,,setN_emailProps] = useInput(dataConcertPr.n_email||""); // 入力者メールアドレス
  const [n_email1Props,,setN_email1Props] = useInput(dataConcertPr.n_email1||""); // 入力者メールアドレス
  const [n_email2Props,,setN_email2Props] = useInput(dataConcertPr.n_email2||""); // 入力者メールアドレス
  //const [n_telProps,,setN_telProps] = useInput(dataConcertPr.n_tel||"","number"); // 入力者電話番号
  const [n_telaProps,,setN_telaProps] = useInput(dataConcertPr.n_tela||"","number"); // 入力者電話番号a
  const [n_telbProps,,setN_telbProps] = useInput(dataConcertPr.n_telb||"","number"); // 入力者電話番号b
  const [n_telcProps,,setN_telcProps] = useInput(dataConcertPr.n_telc||"","number"); // 入力者電話番号c


  const [playerJa, setPlayerJa] = useState(dataConcertPr.playerJa||[{id:1,text:""}]);
  const [playerEn, setPlayerEn] = useState(dataConcertPr.playerEn||[{id:1,text:""}]);
  const [partsJa, setPartsJa] = useState(dataConcertPr.partsJa||[]);
  const [partsEn, setPartsEn] = useState(dataConcertPr.partsEn||[]);
  const [programJa, setProgramJa] = useState(dataConcertPr.programJa||[{id:1,text:""}]);
  const [programEn, setProgramEn] = useState(dataConcertPr.programEn||[{id:1,text:""}]);
  const [priceJa, setPriceJa] = useState(dataConcertPr.priceJa||[{id:1,text:""}]);
  const [priceEn, setPriceEn] = useState(dataConcertPr.priceEn||[{id:1,text:""}]);

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [mailCheck, setMailCheck] = useState(true); // メールアドレスチェック
  const [n_mailCheck, setNMailCheck] = useState(true); // 入力者メールアドレスチェック

  const [telCheck, setTelCheck] = useState(true); // 電話番号チェック
  const [telEqCheck, setTelEqCheck] = useState(true); // 電話番号同一チェック
  const [n_telEqCheck, setNTelEqCheck] = useState(true); // 電話番号同一チェック
  const [telOrMailCheck, setTelOrMailCheck] = useState(true); // 電話番号チェック

  const [selectedFile, setSelectedFile] = useState(dataConcertPr.selectedFile); // 画像登録用
  const [selectedFile2, setSelectedFile2] = useState(dataConcertPr.selectedFile2); // 画像登録用
	const [isFilePicked, setIsFilePicked] = useState(false); // 画像登録用
	const [isFilePicked2, setIsFilePicked2] = useState(false); // 画像登録用
  const [isFileSizeTooBig, setIsFileSizeTooBig] = useState(false); // 画像登録用
  const [isFileSizeTooBig2, setIsFileSizeTooBig2] = useState(false); // 画像登録用
  const [uploadedFile, setUploadedFile] = useState(dataConcertPr.keyimage); // 画像登録用
  const [uploadedFile2, setUploadedFile2] = useState(dataConcertPr.keyimage2); // 画像登録用

  const [agreeProps] = useCheckbox(dataConcertPr.agree ? [...dataConcertPr.agree] : []); // 同意チェック

  // 利用規約の最後の位置が現れたか
  const { ref, inView } = useInView({
    triggerOnce: true, // 最初の一度だけ実行
  });

  useEffect(()=>{
    getConcert(id);
    getConcertPlayerParts();
    if(n_nameProps.value===""){
      myInfo();
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },[data]);

  useEffect(()=>{
    if(n_nameProps.value===""){
      if(dataMy.r_name){
        setN_nameProps(dataMy.r_name);
        setN_l_nameProps(dataMy.r_d_l_name);
        setN_f_nameProps(dataMy.r_d_f_name);
        setN_emailProps(dataMy.email);
        if(dataMy.email){
          const mailpart = dataMy.email.split('@');
          setN_email1Props(mailpart[0]);
          setN_email2Props(mailpart[1]);
        }
        if(dataMy.r_tel1){
          const telpart = dataMy.r_tel1.split('-');
          setN_telaProps(telpart[0]);
          setN_telbProps(telpart[1]);
          setN_telcProps(telpart[2]);
        }
      }
    }
  },[dataMy]);

  useEffect(()=>{
    let myPartsJa = [];
    let myPartsEn = [];
    if ( dataPlayerParts.parts ) {
      dataPlayerParts.parts.forEach(part => myPartsJa.push({label: part.name_ja}));
      dataPlayerParts.parts.forEach(part => myPartsEn.push({label: part.name_en}));
      setPartsJa(myPartsJa);
      setPartsEn(myPartsEn);
    }
  },[dataPlayerParts]);

  // メールアドレスチェック
  useEffect(() => {
    if ( emailProps.value !== ''){
      if ( emailProps.value === email1Props.value + '@' + email2Props.value ) {
        setMailCheck(true);
      } else {
        setMailCheck(false);
      }
    } else {
      setMailCheck(true);
    }
  },[emailProps.value, email1Props.value, email2Props.value]);

  // メールアドレスチェック
  useEffect(() => {
    if ( n_emailProps.value !== ''){
      if ( n_emailProps.value === n_email1Props.value + '@' + n_email2Props.value ) {
        setNMailCheck(true);
      } else {
        setNMailCheck(false);
      }
    } else {
      setNMailCheck(true);
    }
  },[n_emailProps.value, n_email1Props.value, n_email2Props.value]);

  // 電話番号チェック
  useEffect(() => {
    let eq=true;
    if (telaProps.value==="" && telbProps.value==="" && telcProps.value==="") {
      setTelCheck(true);
    } else if (telaProps.value==="" || telbProps.value==="" || telcProps.value==="") {
      setTelCheck(false);
    } else {
      setTelCheck(true);
      if(telaProps.value + telbProps.value + telcProps.value == telProps.value) {
        eq=true;
      } else {
        eq=false;
      }
    }
    setTelEqCheck(eq);
  },[telaProps.value, telbProps.value, telcProps.value, telProps.value]);

  // 電話番号チェック
//  useEffect(() => {
//    let eq=true;
//    if (n_telaProps.value==="" && n_telbProps.value==="" && n_telcProps.value==="") {
//    } else if (n_telaProps.value==="" || n_telaProps.value==="" || n_telaProps.value==="") {
//    } else {
//      if(n_telaProps.value + n_telbProps.value + n_telcProps.value == n_telProps.value) {
//        eq=true;
//      } else {
//        eq=false;
//      }
//    }
//    setNTelEqCheck(eq);
//  },[n_telaProps.value, n_telbProps.value, n_telcProps.value, n_telProps.value]);


  //
  useEffect(() => {
    if ( chargeProps.val==="0" ) {
      setPriceJa([{id:1,text:""}]);
      setPriceEn([{id:1,text:""}]);
    }
  },[chargeProps.val]);



  useEffect(()=>{
    let err = false;

    // 必須チェック
    if(id === 'new') {
      console.log(sdateProps.value);
      console.log(venueProps.value);
      if(sdateProps.value==="") err=true;
      if(venueProps.value==="") err=true;
    }

    if(publish_forProps.val==="") err=true;
    if(publish_paperProps.val==="") err=true;
    if(open_timeProps.value==="") err=true;
    if(start_timeProps.value==="") err=true;
    if(end_timeProps.value==="") err=true;
    if(title_jaProps.value==="") err=true;
    if(ticket_typeProps.val==="") err=true;
    if(chargeProps.val==="") err=true;
    if(contact_jaProps.value==="") err=true;
    if(telaProps.val==="" || emailProps.val==="") err=true;

    if(n_nameProps.val==="") err=true;
    if(n_l_nameProps.val==="") err=true;
    if(n_f_nameProps.val==="") err=true;
    if(n_emailProps.val==="") err=true;
    if(n_telaProps.val==="") err=true;
    if(n_telbProps.val==="") err=true;
    if(n_telcProps.val==="") err=true;

    if ((telaProps.value==="" || telbProps.value==="" || telcProps.value==="") && emailProps.value==="") {
      err=true;
      setTelOrMailCheck(false);
    } else {
      setTelOrMailCheck(true);
    }

    if(!telEqCheck){
      err=true;
    }
    if(!n_telEqCheck){
      err=true;
    }

    setHasError(err);

  });

  const playerJaAdd = () => {
    let id = 1;
    if(playerJa.length) {
      id = playerJa.slice(-1)[0].id + 1;
    }
    setPlayerJa([
      ...playerJa,
      {id}
    ]);
  }
  const playerJaDel = (e) => {
    setPlayerJa(playerJa.filter(item => item.id != e.currentTarget.value));
  }
  const playerJaNameUpdate = (e) => {
    const id = e.currentTarget.dataset.id;
    const name = e.currentTarget.value;
    const newarray = playerJa.map(item => {
      if (item.id == id ) {
        return {
          ...item,
          name
        };
      } else {
        return item;
      }
    })
    setPlayerJa(newarray);
  }
  const playerJaPartUpdate = (e, newValue) => {
    const id = e.currentTarget.dataset.id;

    if (typeof newValue === 'string') {
      const part = newValue;

      if (id) {
        const newarray = playerJa.map(item => {
          if (item.id == id ) {
            return {
              ...item,
              part
            };
          } else {
            return item;
          }
        })
        setPlayerJa(newarray);
      }

    }
  }

  const playerEnAdd = () => {
    let id = 1;
    if(playerEn.length) {
      id = playerEn.slice(-1)[0].id + 1;
    }
    setPlayerEn([
      ...playerEn,
      {id}
    ]);
  }
  const playerEnDel = (e) => {
    setPlayerEn(playerEn.filter(item => item.id != e.currentTarget.value));
  }
  const playerEnNameUpdate = (e) => {
    const id = e.currentTarget.dataset.id;
    let name = e.currentTarget.value;
    name = name.replace(/[０-９ａ-ｚＡ-Ｚ]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248))
               .replace(/[^a-zA-Z0-9\s/\!\*\@,;:\.\⁼\-\#\"\'\[\]\(\)\&\{\}\<\>\_\?\+\%\$\~]/g, '');
    const newarray = playerEn.map(item => {
      if (item.id == id ) {
        return {
          ...item,
          name
        };
      } else {
        return item;
      }
    })
    setPlayerEn(newarray);
  }
  const playerEnPartUpdate = (e, newValue) => {
    const id = e.currentTarget.dataset.id;

    if (typeof newValue === 'string') {
      let part = newValue;
      part = part.replace(/[０-９ａ-ｚＡ-Ｚ]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248))
                 .replace(/[^a-zA-Z0-9\s/\!\*\@,;:\.\⁼\-\#\"\'\[\]\(\)\&\{\}\<\>\_\?\+\%\$\~]/g, '');

      if (id) {
        const newarray = playerEn.map(item => {
          if (item.id == id ) {
            return {
              ...item,
              part
            };
          } else {
            return item;
          }
        })
        setPlayerEn(newarray);
      }

    }
  }

  const programJaAdd = () => {
    let id = 1;
    if(programJa.length) {
      id = programJa.slice(-1)[0].id + 1;
    }
    setProgramJa([
      ...programJa,
      {id}
    ]);
  }
  const programJaDel = (e) => {
    setProgramJa(programJa.filter(item => item.id != e.currentTarget.value));
  }
  const programJaTitleUpdate = (e) => {
    const id = e.currentTarget.dataset.id;
    const title = e.currentTarget.value;
    const newarray = programJa.map(item => {
      if (item.id == id ) {
        return {
          ...item,
          title
        };
      } else {
        return item;
      }
    })
    setProgramJa(newarray);
  }
  const programJaAuthorUpdate = (e, newValue) => {
    const id = e.currentTarget.dataset.id;
    const author = e.currentTarget.value;
    const newarray = programJa.map(item => {
      if (item.id == id ) {
        return {
          ...item,
          author
        };
      } else {
        return item;
      }
    })
    setProgramJa(newarray);
  }

  const programEnAdd = () => {
    let id = 1;
    if(programEn.length) {
      id = programEn.slice(-1)[0].id + 1;
    }
    setProgramEn([
      ...programEn,
      {id}
    ]);
  }
  const programEnDel = (e) => {
    setProgramEn(programEn.filter(item => item.id != e.currentTarget.value));
  }
  const programEnTitleUpdate = (e) => {
    const id = e.currentTarget.dataset.id;
    let title = e.currentTarget.value;
    title = title.replace(/[０-９ａ-ｚＡ-Ｚ]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248))
                 .replace(/[^a-zA-Z0-9\s/\!\*\@,;:\.\⁼\-\#\"\'\[\]\(\)\&\{\}\<\>\_\?\+\%\$\~]/g, '');
    const newarray = programEn.map(item => {
      if (item.id == id ) {
        return {
          ...item,
          title
        };
      } else {
        return item;
      }
    })
    setProgramEn(newarray);
  }
  const programEnAuthorUpdate = (e, newValue) => {
    const id = e.currentTarget.dataset.id;
    let author = e.currentTarget.value;
    author = author.replace(/[０-９ａ-ｚＡ-Ｚ]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248))
                   .replace(/[^a-zA-Z0-9\s/\!\*\@,;:\.\⁼\-\#\"\'\[\]\(\)\&\{\}\<\>\_\?\+\%\$\~]/g, '');
    const newarray = programEn.map(item => {
      if (item.id == id ) {
        return {
          ...item,
          author
        };
      } else {
        return item;
      }
    })
    setProgramEn(newarray);
  }


  const priceJaAdd = () => {
    let id = 1;
    if(priceJa.length) {
      id = priceJa.slice(-1)[0].id + 1;
    }
    setPriceJa([
      ...priceJa,
      {id}
    ]);
    setPriceEn([
      ...priceEn,
      {id}
    ]);
  }
  const priceJaDel = (e) => {
    setPriceJa(priceJa.filter(item => item.id != e.currentTarget.value));
    setPriceEn(priceEn.filter(item => item.id != e.currentTarget.value));
  }
  const priceJaNameUpdate = (e) => {
    const id = e.currentTarget.dataset.id;
    const name = e.currentTarget.value;
    const newarray = priceJa.map(item => {
      if (item.id == id ) {
        return {
          ...item,
          name
        };
      } else {
        return item;
      }
    })
    setPriceJa(newarray);
  }
  const priceJaPriceUpdate = (e, newValue) => {
    const id = e.currentTarget.dataset.id;
    let price = e.currentTarget.value;
    price = price.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248)).replace(/\D/g, '');

    const newarray = priceJa.map(item => {
      if (item.id == id ) {
        return {
          ...item,
          price
        };
      } else {
        return item;
      }
    })
    setPriceJa(newarray);
    const newarraye = priceEn.map(item => {
      if (item.id == id ) {
        return {
          ...item,
          price
        };
      } else {
        return item;
      }
    })
    setPriceEn(newarraye);
  }


  const priceEnAdd = () => {
    let id = 1;
    if(priceEn.length) {
      id = priceEn.slice(-1)[0].id + 1;
    }
    setPriceEn([
      ...priceEn,
      {id}
    ]);
    setPriceJa([
      ...priceJa,
      {id}
    ]);
  }
  const priceEnDel = (e) => {
    setPriceEn(priceEn.filter(item => item.id != e.currentTarget.value));
    setPriceJa(priceJa.filter(item => item.id != e.currentTarget.value));
  }
  const priceEnNameUpdate = (e) => {
    const id = e.currentTarget.dataset.id;
    let name = e.currentTarget.value;
    name = name.replace(/[０-９ａ-ｚＡ-Ｚ]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248))
               .replace(/[^a-zA-Z0-9\s/\!\*\@,;:\.\⁼\-\#\"\'\[\]\(\)\&\{\}\<\>\_\?\+\%\$\~]/g, '');
    const newarray = priceEn.map(item => {
      if (item.id == id ) {
        return {
          ...item,
          name
        };
      } else {
        return item;
      }
    })
    setPriceEn(newarray);
  }
  const priceEnPriceUpdate = (e, newValue) => {
    const id = e.currentTarget.dataset.id;
    let price = e.currentTarget.value;
    price = price.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248)).replace(/\D/g, '');

    const newarray = priceEn.map(item => {
      if (item.id == id ) {
        return {
          ...item,
          price
        };
      } else {
        return item;
      }
    })
    setPriceEn(newarray);
    const newarrayj = priceJa.map(item => {
      if (item.id == id ) {
        return {
          ...item,
          price
        };
      } else {
        return item;
      }
    })
    setPriceJa(newarrayj);
  }


  const fileChange = (event) => {
    const file = event.target.files[0];
    if ( file.size > 3 * 1024 * 1024 ) { // 3MBまで
      setIsFileSizeTooBig(true);
      setIsFilePicked(false);
      setSelectedFile(undefined);
    } else {
      setIsFileSizeTooBig(false);
      setSelectedFile(file);
      setIsFilePicked(true);
      postConcertImage(id,file,setUploadedFile);
    }
	};

  const fileChange2 = (event) => {
    const file = event.target.files[0];
    if ( file.size > 3 * 1024 * 1024 ) { // 3MBまで
      setIsFileSizeTooBig2(true);
      setIsFilePicked2(false);
      setSelectedFile2(undefined);
    } else {
      setIsFileSizeTooBig2(false);
      setSelectedFile2(file);
      setIsFilePicked2(true);
      postConcertImage(id,file,setUploadedFile2);
    }
	};

  const convertDate = (date) => {
    const dow=['日','月','火','水','木','金','土'];
    const d = new Date(date);
    return d.getFullYear() +'年' + (d.getMonth() +1) +'月' + d.getDate() +'日' + '(' + dow[d.getDay()] + ')';
  }

  const nocopy = (e) => {
    console.log('nocopy');
    e.preventDefault();
  }

  // 確認画面へ
  const submit = (e) => {
    e.preventDefault();

    const constPlayerJa = playerJa.filter(item => !(item.name == undefined || item.name == '') );
    const constPlayerEn = playerEn.filter(item => !(item.name == undefined || item.name == '') );

    const constProgramJa = programJa.filter(item => !(item.title == undefined || item.title == '') );
    const constProgramEn = programEn.filter(item => !(item.title == undefined || item.title == '') );
    const constPriceJa = priceJa.filter(item => !(item.price == undefined || item.price == '') );
    const constPriceEn = priceEn.filter(item => !(item.price == undefined || item.price == '') );


    setDataConcertPr( {
      sdate          :sdateProps.value,
      venue          :venueProps.value,
      keyimage       :uploadedFile,
      keyimage2      :uploadedFile2,
      selectedFile   :selectedFile,
      selectedFile2  :selectedFile2,
      publish_for    :publish_forProps.val,
      publish_paper  :publish_paperProps.val,
      open_time      :open_timeProps.value,
      start_time     :start_timeProps.value,
      end_time       :end_timeProps.value,
      title_ja       :title_jaProps.value,
      title_en       :title_enProps.value,
      ticket_type    :ticket_typeProps.val,
      ticket_type_text_ja  :ticket_type_text_jaProps.value,
      ticket_type_text_en  :ticket_type_text_enProps.value,
      charge         :chargeProps.val,
      ticket_sale    :ticket_saleProps.val,
      ticket_sale_text_ja  :ticket_sale_text_jaProps.value,
      ticket_sale_text_en  :'',
      ticket_sale_date     :ticket_sale_dateProps.value,
      children_enter :children_enterProps.val,
      url_ja         :url_jaProps.value,
      url_en         :url_enProps.value,
      comment        :commentProps.value,
      contact_ja     :contact_jaProps.value,
      contact_en     :contact_enProps.value,

      tela           :telaProps.value,
      telb           :telbProps.value,
      telc           :telcProps.value,
      email          :emailProps.value,
      email1         :email1Props.value,
      email2         :email2Props.value,
      n_name         :n_nameProps.value,
      n_l_name       :n_l_nameProps.value,
      n_f_name       :n_f_nameProps.value,
      n_email        :n_emailProps.value,
      n_email1       :n_email1Props.value,
      n_email2       :n_email2Props.value,
      n_tela         :n_telaProps.value,
      n_telb         :n_telbProps.value,
      n_telc         :n_telcProps.value,

      playerJa :constPlayerJa,
      playerEn :constPlayerEn,
      partsJa,
      partsEn,
      programJa :constProgramJa,
      programEn :constProgramEn,
      priceJa   :constPriceJa,
      priceEn   :constPriceEn,

      agree     :agreeProps.val,

      mode: "confirm"
    });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
    { dataConcert.id || id=='new' ?
      <>
        <h1 className="pagetopLabel">公演情報登録</h1><br />

        <div className="tabs">
          <input id="jp" type="radio" name="tab_item" {...tab_itemProps} value="jp" checked={tab_itemProps.val=="jp"} /><label className="tab_item" htmlFor="jp">公演情報（和文）</label>
          <input id="eng" type="radio" name="tab_item" {...tab_itemProps} value="eng" checked={tab_itemProps.val=="eng"} /><label className="tab_item" htmlFor="eng">公演情報（英文）</label>

          <div className="tab_content" id="jp_content">
            <table className="Form" summary="#">
              <tbody>
              <tr>
                <th>掲載希望<span>※必須</span></th>
                <td><label><input type="radio" {...publish_forProps} value="2" checked={publish_forProps.val==2} />公式ウェブサイト及び紙面版コンサートカレンダーへの掲載を希望する<br />
                      <span>※紙面掲載の場合は、以下の締切(公演日の3～5か月前の15日)迄に情報を提出してください。</span>
                      </label>
                      <ul className="attentionlist"><li>4・5・6月公演：12月15日まで</li>
                          <li>7・8・9月公演：3月15日まで</li>
                          <li>10・11・12月公演：6月15日まで</li>
                          <li>1・2・3月公演：9月15日まで</li>
                      </ul>
                  <label><input type="radio" {...publish_forProps} value="1" checked={publish_forProps.val==1} />公式ウェブサイトのみの掲載を希望する<br />
                  <span>※関係者のみの公演で掲載希望の場合は、ウェブサイトのみの掲載となります。</span></label><br />
                  <label><input type="radio" {...publish_forProps} value="0" checked={publish_forProps.val==0} />掲載を希望しない</label></td>
              </tr>
              <tr>
                <th>公演日・曜日{ id==='new' ? <span>※必須</span> : <></> }</th>
                { id==='new' ?
                <td><input type="date" {...sdateProps} required /></td>
                :
                <td>{ convertDate(dataConcert.sdate) }</td>
                }
              </tr>
              <tr>
                <th>開場時間/開演時間/終演時間 <span>※必須</span></th>
                <td>
                  開場<input type="time" {...open_timeProps} min="10:00" max="22:00" step="1800" required className={open_timeProps.value==="" ? 'err' : ''} />
                  開演<input type="time" {...start_timeProps} min="10:00" max="22:00" step="1800" required className={start_timeProps.value==="" ? 'err' : ''} />
                  終演<input type="time" {...end_timeProps} min="10:00" max="22:00" step="1800" required className={end_timeProps.value==="" ? 'err' : ''} />
                </td>
              </tr>
              <tr>
                <th>会場{ id==='new' ? <span>※必須</span> : <></> }</th>
                { id==='new' ?
                <td>
                  <select {...venueProps}>
                    <option value="">会場 選択</option>
                    <option value="10">大ホール</option>
                    <option value="20">小ホール</option>
                    <option value="30">レセプションルーム</option>
                  </select>
                </td>
                :
                <td>
                  {dataConcert.venue==10 ? '大ホール' : ''}
                  {dataConcert.venue==20 ? '小ホール' : ''}
                  {dataConcert.venue==30 ? 'レセプションルーム' : ''}
                  {dataConcert.venue==99 ? 'その他' : ''}
                </td>
                }
              </tr>
              <tr>
                <th>公演名<span>※必須</span></th>
                <td><input type="text" {...title_jaProps} required size="80" className={title_jaProps.value==="" ? 'err' : ''} /><br /><span>（全角44文字以内（半角カナ不可））</span></td>
              </tr>
              <tr>
                <th>主催/協力/貸館</th>
                { id==='new' ?
                <td>
                貸館公演
                </td>
                :
                <td>
                {dataConcert.type==0 ? '主催公演' : ''}
                {dataConcert.type==1 ? '協力公演' : ''}
                {dataConcert.type==2 ? '貸館公演' : ''}
                </td>
                }
              </tr>

              <tr>
                <th>出演者/演奏団体</th>
                <td>
                  { playerJa.map((player) => (
                    <div key={player.id}>
                      <input type="text" size="30" placeholder="みなと 太郎（姓･名の間は半角あけてください）"
                        data-id={player.id} onChange={playerJaNameUpdate}
                        value={player.name}
                      />
                      <Autocomplete
                        value={player.part}
                        onChange={playerJaPartUpdate}
                        freeSolo
                        autoSelect
                        ListboxProps={{
                          sx: { fontSize: 12 },
                        }}
                        sx={{
                          display: 'inline-block',
                          '& input': {
                            width: 200,
                            bgcolor: 'background.paper',
                            color: (theme) =>
                              theme.palette.getContrastText(theme.palette.background.paper),
                          },
                          '& .MuiAutocomplete-input, & .MuiInputLabel-root, & .MuiAutocomplete-option': {
                            fontSize: 12,
                          },
                        }}
                        options={partsJa}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <input type="text" {...params.inputProps}
                              placeholder="パート"
                              data-id={player.id}
                            />
                          </div>
                        )}
                      />
                      <button type="button" className="additionbtn" onClick={playerJaDel} value={player.id}><i className="fas fa-minus"></i></button>
                    </div>
                  ))}
                  {playerJa.length < 3 &&
                  <button type="button" className="additionbtn" onClick={playerJaAdd}><i className="fas fa-plus"></i></button>
                  }
                  <br />
                  <span>（日本語表記でご入力ください（半角カナ不可））入力欄をクリックするとパートの選択肢が出てきます。選択肢から当てはまるものを選択してください。あてはまるものがない場合は直接入力してください。入力欄は３つまで増やせます。</span></td>
              </tr>
              <tr>
                <th>作曲者/曲目</th>
                <td>
                  { programJa.map((program) => (
                    <div key={program.id}>
                      <input type="text" data-id={program.id} onChange={programJaAuthorUpdate} value={program.author} size="30" placeholder="（作曲者）ベートーヴェン" />
                      <input type="text" data-id={program.id} onChange={programJaTitleUpdate} value={program.title} size="40" placeholder="（曲目）ピアノ協奏曲第5番 変ホ長調 「皇帝」 Op. 73" />
                      <button type="button" className="additionbtn" onClick={programJaDel} value={program.id}><i className="fas fa-minus"></i></button>
                    </div>
                  )) }
                  {programJa.length < 3 &&
                  <button type="button" className="additionbtn" onClick={programJaAdd}><i className="fas fa-plus"></i></button>
                  }
                  <br />
                  <span>入力例2：（作曲者名）ドビュッシー（曲目）《版画》より〈雨の庭〉<br />（日本語表記でご入力ください（半角カナ不可））入力欄は３つまで増やせます。</span>
                </td>
              </tr>
              <tr>
                <th>チケット種別<span>※必須</span></th>
                <td>
                  <label className={ticket_typeProps.val==="" ? 'err' : ''}><input type="radio" {...ticket_typeProps} value="10" checked={ticket_typeProps.val==10} />全席指定</label>
                  <label className={ticket_typeProps.val==="" ? 'err' : ''}><input type="radio" {...ticket_typeProps} value="20" checked={ticket_typeProps.val==20} />全席自由</label>
                  <label className={ticket_typeProps.val==="" ? 'err' : ''}><input type="radio" {...ticket_typeProps} value="30" checked={ticket_typeProps.val==30} />当日座席指定</label>
                  <label className={ticket_typeProps.val==="" ? 'err' : ''}><input type="radio" {...ticket_typeProps} value="40" checked={ticket_typeProps.val==40} />関係者のみ</label>
                  <label className={ticket_typeProps.val==="" ? 'err' : ''}><input type="radio" {...ticket_typeProps} value="99" checked={ticket_typeProps.val==99} />その他</label>
                  <input type="text" {...ticket_type_text_jaProps} disabled={ticket_typeProps.val!=99} placeholder="その他入力" /><br />
                  <span>※大ホールで自由席をご希望の場合は事前にホールにご相談ください</span>
                </td>
              </tr>
              <tr>
                <th>入場料金<span>※必須</span></th>
                <td>
                  <label className={chargeProps.val==="" ? 'err' : ''}><input type="radio" {...chargeProps} value="1" checked={chargeProps.val==="1"} />有料</label>
                  <label className={chargeProps.val==="" ? 'err' : ''}><input type="radio" {...chargeProps} value="0" checked={chargeProps.val==="0"} />無料</label>
                  <br />
                  { chargeProps.val==="1" && <>
                    { priceJa.map((price) => (
                    <div key={price.id}>
                      <input type="text" data-id={price.id} onChange={priceJaNameUpdate} value={price.name} size="14" placeholder="席種名入力" />
                      <input type="text" data-id={price.id} onChange={priceJaPriceUpdate} value={price.price} placeholder="値段を入力" pattern="^[0-9a-za-z]+$" />円
                      <button type="button" className="additionbtn" onClick={priceJaDel} value={price.id}><i className="fas fa-minus"></i></button>
                    </div>
                    )) }
                    {priceJa.length < 5 &&
                    <button type="button" className="additionbtn" onClick={priceJaAdd}><i className="fas fa-plus"></i></button>
                    }
                    <br />
                    <span>入力例：（席種名）S　（値段）2,000<br />入力欄は５つまで増やせます。それ以上の席種は「その他」欄にご入力ください。</span>
                  </>}
                </td>
              </tr>
              <tr>
                <th>チケット販売方法</th>
                <td>
                  <label><input type="radio" {...ticket_saleProps} value="10" checked={ticket_saleProps.val==10} />チケットぴあに販売委託（ホールチケットセンター（窓口･電話･WEB）を含む）<br /><span>※チケットぴあに委託された場合、ホールチケットセンターでも取扱いとなります。</span></label><br />
                  <label><input type="radio" {...ticket_saleProps} value="20" checked={ticket_saleProps.val==20} />ホールチケットセンター委託販売（ホールチケットセンター窓口のみ）</label><br />
                  <label><input type="radio" {...ticket_saleProps} value="30" checked={ticket_saleProps.val==30} />主催者のみの販売、またはチケットぴあ以外のチケット会社に委託</label><br />
                  <label><input type="radio" {...ticket_saleProps} value="99" checked={ticket_saleProps.val==99} />その他</label>
                  <input type="text" {...ticket_sale_text_jaProps} disabled={ticket_saleProps.val!=99} placeholder="その他入力" /></td>
              </tr>
              <tr>
                <th>チケット販売・受付開始日</th>
                <td><input type="date" {...ticket_sale_dateProps} required /></td>
              </tr>
              <tr>
                <th>未就学児の入場</th>
                <td>
                  <label><input type="radio" {...children_enterProps} value="30" checked={children_enterProps.val==30} />未就学児入場不可</label>
                  <label><input type="radio" {...children_enterProps} value="10" checked={children_enterProps.val==10} />未就学児入場可【チケット要】</label>
                  <label><input type="radio" {...children_enterProps} value="20" checked={children_enterProps.val==20} />未就学児入場可【チケット不要】</label>
                </td>
              </tr>
              <tr>
                <th>ホームページURL</th>
                <td>
                  <input type="text" {...url_jaProps} />
                </td>
              </tr>
              { (dataConcert.type==0 || dataConcert.type==1) &&
              <>
              <tr>
                <th>画像･イメージ</th>
                <td>
                  <label style={{padding:0, marginBottom:"1vw"}}>
                    <Input accept=".png, .jpg, .jpeg, .gif" id="contained-button-file" type="file" onChange={fileChange} />
                    <Button variant="contained" component="span" style={{margin:0}} >
                      画像・イメージファイル選択
                    </Button>
                  </label>
                  {uploadedFile && selectedFile && selectedFile.name.match(/\.(jpeg|jpg|png|gif)$/i) ?
                  <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                        component="img"
                        image={process.env.REACT_APP_API_ENDPOINT + 'my/concert/' + id + '/image/' + uploadedFile}
                      />
                  </Card>
                  : <br />}
                  {selectedFile && <>
                    <span>{selectedFile.name} </span>
                    <span>({ Math.ceil(selectedFile.size / 1000) } kb)</span>
                  </>}
                  {isFileSizeTooBig ? <span className="err">ファイルサイズが大きすぎます</span> : ''}
                  <br />
                  <span>画像形式（JPG・PNG）、ファイルサイズ（３MB以下）</span>
                </td>
              </tr>
              <tr>
                <th>チラシPDF</th>
                <td>
                  <label style={{padding:0, marginBottom:"1vw"}}>
                    <Input accept=".pdf" id="contained-button-file2" type="file" onChange={fileChange2} />
                    <Button variant="contained" component="span" style={{margin:0}} >
                      PDFファイル選択
                    </Button>
                  </label>
                  {uploadedFile2 && selectedFile2 && selectedFile2.name.match(/\.(jpeg|jpg|png|gif)$/i) ?
                  <Card sx={{ maxWidth: 345 }}>
                     <CardMedia
                        component="img"
                        image={process.env.REACT_APP_API_ENDPOINT + 'my/concert/' + id + '/image/' + uploadedFile2}
                      />
                  </Card>
                  : <br />}
                  {selectedFile2 && <>
                    <span>{selectedFile2.name} </span>
                    <span>({ Math.ceil(selectedFile2.size / 1000) } kb)</span>
                  </>}
                  {isFileSizeTooBig2 ? <span className="err">ファイルサイズが大きすぎます</span> : ''}
                  <br />
                  <span>PDf形式、ファイルサイズ（３MB以下）</span>
                </td>
              </tr>
              </>
            }
              <tr>
                <th>その他</th>
                <td>
                  <textarea {...commentProps} placeholder="事前申込制や整理番号を配布する場合、入力しきれなかった席種情報がある場合は、こちらの欄にご入力ください。（最大100文字）" rows="3" cols="84">{commentProps.value}</textarea></td>
              </tr>
              <tr>
                <th>「問合せ先名称」の紙面版コンサートカレンダーへの掲載希望<span>※必須</span></th>
                <td>
                  <label><input type="radio" {...publish_paperProps} value="1" checked={publish_paperProps.val==1} />希望する</label>
                  <label><input type="radio" {...publish_paperProps} value="0" checked={publish_paperProps.val==0} />希望しない</label>
                </td>
              </tr>
              <tr>
                <th>問合せ先<span>※必須</span></th>
                <td><input type="text" {...contact_jaProps} size="80" className={contact_jaProps.value==="" ? 'err' : ''} /><br /><span>（団体名、もしくは個人名（苗字のみ））</span></td>
              </tr>
              <tr>
                <th>問合せ先電話番号</th>
                <td>
                  <input size="10" type="text" maxLength="3" {...telaProps}
                    onCopy={nocopy}
                    onCut={nocopy}
                    className={ (telaProps.value==="" || telbProps.value==="" || telcProps.value==="") && emailProps.value==="" ? 'err' : ''}
                  />
                  ー
                  <input size="10" type="text" maxLength="4" {...telbProps}
                    onCopy={nocopy}
                    onCut={nocopy}
                    className={ (telaProps.value==="" || telbProps.value==="" || telcProps.value==="") && emailProps.value==="" ? 'err' : ''}
                    />
                  ー
                  <input size="10" type="text" maxLength="4" {...telcProps}
                    onCopy={nocopy}
                    onCut={nocopy}
                    className={ (telaProps.value==="" || telbProps.value==="" || telcProps.value==="") && emailProps.value==="" ? 'err' : ''}
                    /><span>（半角数字）</span><br />
                  { !telOrMailCheck  && <><span className="notice">※問合せ先電話番号・メールアドレスのどちらか、もしくは両方を入力してください。ウェブサイトに掲載いたします。</span></>}
                    </td>
              </tr>
              <tr>
                <th>問合せ先電話番号（確認）</th>
                <td>
                  <input size="30" type="text" maxLength="11" {...telProps}
                    onCopy={nocopy}
                    onCut={nocopy}
                    className={ telProps.value==="" && emailProps.value==="" ? 'err' : ''}
                  />
                  <span>（半角数字）</span><br />
                  { !telEqCheck  && <><span className="notice">問合せ先電話番号が一致しません</span></>}
                    </td>
              </tr>
              <tr>
                <th>問合せ先メールアドレス</th>
                <td>
                    <input type="email" {...emailProps} size="40" width="100%" required
                    onCopy={nocopy}
                    onCut={nocopy}
                    className={ (telaProps.value==="" || telbProps.value==="" || telcProps.value==="") && emailProps.value==="" ? 'err' : ''}
                    autoComplete="email" pattern="^[0-9a-zA-Z_\.\+\-]+@[0-9a-zA-Z_\.\-]+\.[0-9a-zA-Z_\.\-]+$"
                    /><span>（半角英数字）</span><br />
                  { !telOrMailCheck  && <><span className="notice">※問合せ先電話番号・メールアドレスのどちらか、もしくは両方を入力してください。ウェブサイトに掲載いたします。</span></>}
                </td>
              </tr>
              <tr>
                <th>問合せ先メールアドレス（確認）</th>
                <td>
                  <input type="text" {...email1Props} size="30" width="100%" required pattern="^[0-9a-zA-Z_\.\+\-]+$"
                    onCopy={nocopy}
                    onCut={nocopy}
                    className={ (telaProps.value==="" || telbProps.value==="" || telcProps.value==="") && emailProps.value==="" ? 'err' : ''}
                    />
                  ＠
                  <input type="text" {...email2Props} size="14" width="100%" required pattern="^[0-9a-zA-Z_\.\-]+\.[0-9a-zA-Z_\.\-]+$"
                    onCopy={nocopy}
                    onCut={nocopy}
                    className={ (telaProps.value==="" || telbProps.value==="" || telcProps.value==="") && emailProps.value==="" ? 'err' : ''}
                    /><span>（半角英数字）</span><br />
                  { !mailCheck  && <span className="notice">メールアドレスが一致しません</span>}
                </td>
              </tr>
            </tbody>
            </table>

            <h6>入力者連絡先（非公開情報）</h6>
            <p>※入力に不備がある場合にご連絡します。公演内容について直接やりとりのできる方の連絡先をご入力ください。</p>
            <table className="Form" summary="#">
              <tbody><tr>
                <th>団体名 <span>※必須</span></th>
                <td><input {...n_nameProps} size="40" type="text" required className={n_nameProps.value==="" ? 'err' : ''} /></td>
              </tr>
              <tr>
                <th>担当者名 <span>※必須</span></th>
                <td>
                  姓<input {...n_l_nameProps} size="14" type="text" required className={n_l_nameProps.value==="" ? 'err' : ''} />
                  名<input {...n_f_nameProps} size="14" type="text" required className={n_f_nameProps.value==="" ? 'err' : ''} />
                </td>
              </tr>
              <tr>
                <th>メールアドレス<span>※必須</span></th>
                <td>
                    <input type="email" {...n_emailProps} size="40" width="100%" required autoComplete="email"
                    onCopy={nocopy}
                    onCut={nocopy}
                     className={n_emailProps.value==="" ? 'err' : ''}
                     pattern="^[0-9a-zA-Z_\.\+\-]+@[0-9a-zA-Z_\.\-]+\.[0-9a-zA-Z_\.\-]+$"
                     /><span>（半角英数字）</span><br />
                </td>
              </tr>
              <tr>
                <th>メールアドレス（確認）<span>※必須</span></th>
                <td>
                  <input type="text" {...n_email1Props} size="30" width="100%" required pattern="^[0-9a-zA-Z_\.\+\-]+$"
                    onCopy={nocopy}
                    onCut={nocopy}
                   className={n_email1Props.value==="" ? 'err' : ''}
                  />
                  ＠
                  <input type="text" {...n_email2Props} size="14" width="100%" required pattern="^[0-9a-zA-Z_\.\-]+\.[0-9a-zA-Z_\.\-]+$"
                    onCopy={nocopy}
                    onCut={nocopy}
                    className={n_email2Props.value==="" ? 'err' : ''}
                  /><span>（半角英数字）</span><br />
                  { !n_mailCheck  && <span className="notice">メールアドレスが一致しません</span>}
                </td>
              </tr>
              <tr>
                <th>電話番号 <span>※必須</span></th>
                <td>
                  <input size="10" type="text" maxLength="3" {...n_telaProps} className={n_telaProps.value==="" ? 'err' : ''} 
                    onCopy={nocopy}
                    onCut={nocopy}
                  />
                  ー
                  <input size="10" type="text" maxLength="4" {...n_telbProps} className={n_telbProps.value==="" ? 'err' : ''} 
                    onCopy={nocopy}
                    onCut={nocopy}
                  />
                  ー
                  <input size="10" type="text" maxLength="4" {...n_telcProps} className={n_telcProps.value==="" ? 'err' : ''} 
                    onCopy={nocopy}
                    onCut={nocopy}
                  /><span>（半角数字）</span>
                </td>
              </tr>
            </tbody>
            </table>
          </div>

          <div className="tab_content" id="eng_content"><br />
            <p>ウェブサイト公演カレンダー（英語版）に掲載希望の場合は入力してください。</p>
            <table className="Form" summary="#">
              <tbody>
              <tr>
                <th>公演名</th>
                <td><input type="text" {...title_enProps} size="80" pattern="^[0-9a-zA-Z_\.\+\-]+@[0-9a-zA-Z_\.\-]+\.[0-9a-zA-Z_\.\-]+$" /><br /><span>（半角88文字以内）</span></td>
              </tr>
              <tr>
                <th>出演者/演奏団体</th>
                <td>
                { playerEn.map((player) => (
                    <div key={player.id}>
                      <input type="text" size="30" placeholder="Minato Taro"
                        data-id={player.id} onChange={playerEnNameUpdate}
                        value={player.name} pattern="^[0-9a-zA-Z_\.\+\-]+@[0-9a-zA-Z_\.\-]+\.[0-9a-zA-Z_\.\-]+$"
                      />
                      <Autocomplete
                        value={player.part}
                        onChange={playerEnPartUpdate}
                        freeSolo
                        autoSelect
                        ListboxProps={{
                          sx: { fontSize: 12 },
                        }}
                        sx={{
                          display: 'inline-block',
                          '& input': {
                            width: 200,
                            bgcolor: 'background.paper',
                            color: (theme) =>
                              theme.palette.getContrastText(theme.palette.background.paper),
                          },
                          '& .MuiAutocomplete-input, & .MuiInputLabel-root, & .MuiAutocomplete-option': {
                            fontSize: 12,
                          },
                        }}
                        options={partsEn}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <input type="text" {...params.inputProps}
                              placeholder="Part"
                              data-id={player.id}
                            />
                          </div>
                        )}
                      />
                      <button type="button" className="additionbtn" onClick={playerEnDel} value={player.id}><i className="fas fa-minus"></i></button>
                    </div>
                  ))}
                  {playerEn.length < 3 &&
                  <button type="button" className="additionbtn" onClick={playerEnAdd}><i className="fas fa-plus"></i></button>
                  }
                  <br />
                  <span>（英語表記でご入力ください）入力欄をクリックするとパートの選択肢が出てきます。選択肢から当てはまるものを選択してください。あてはまるものがない場合は直接入力してください。入力欄は３つまで増やせます。</span>
                </td>
              </tr>
              <tr>
                <th>作曲者/曲目</th>
                <td>
                  { programEn.map((program) => (
                    <div key={program.id}>
                      <input type="text" data-id={program.id} onChange={programEnAuthorUpdate} value={program.author} size="30" placeholder="（作曲者） L. v. Beethoven" pattern="^[0-9a-zA-Z_\.\+\-]+@[0-9a-zA-Z_\.\-]+\.[0-9a-zA-Z_\.\-]+$" />
                      <input type="text" data-id={program.id} onChange={programEnTitleUpdate} value={program.title} size="40" placeholder="（曲目）Piano Concert No. 5 in E-flat major Op. 73 	&quot;Emperor&quot;" />
                      <button type="button" className="additionbtn" onClick={programEnDel} value={program.id}><i className="fas fa-minus"></i></button>
                    </div>
                  )) }
                  {programEn.length < 3 &&
                  <button type="button" className="additionbtn" onClick={programEnAdd}><i className="fas fa-plus"></i></button>
                  }
                  <br />
                  <span>入力例2：（作曲者名）C.Dubussy（曲目）"Jardins sous la pluie" from "Estampes"<br />（英語表記でご入力ください）入力欄は３つまで増やせます。</span>
                </td>
              </tr>
              <tr>
                <th>チケット種別</th>
                <td>
                <label><input type="radio" {...ticket_typeProps} value="10" checked={ticket_typeProps.val==10} />全席指定</label>
                  <label><input type="radio" {...ticket_typeProps} value="20" checked={ticket_typeProps.val==20} />全席自由</label>
                  <label><input type="radio" {...ticket_typeProps} value="30" checked={ticket_typeProps.val==30} />当日座席指定</label>
                  <label><input type="radio" {...ticket_typeProps} value="40" checked={ticket_typeProps.val==40} />関係者のみ</label>
                  <label><input type="radio" {...ticket_typeProps} value="99" checked={ticket_typeProps.val==99} />その他</label>
                  <input type="text" {...ticket_type_text_enProps} disabled={ticket_typeProps.val!=99} placeholder="その他入力" /><br />
                  <span>※大ホールで自由席をご希望の場合は事前にホールにご相談ください</span>
                </td>
              </tr>
              <tr>
                <th>入場料金</th>
                <td>
                  <label><input type="radio" {...chargeProps} value="1" checked={chargeProps.val==="1"} />有料</label>
                  <label><input type="radio" {...chargeProps} value="0" checked={chargeProps.val==="0"} />無料</label>
                  <br />
                  {chargeProps.val==="1" && <>
                    { priceEn.map((price) => (
                    <div key={price.id}>
                      <input type="text" data-id={price.id} onChange={priceEnNameUpdate} value={price.name} size="14" placeholder="席種名入力" />
                      <input type="text" data-id={price.id} onChange={priceEnPriceUpdate} value={price.price} placeholder="値段を入力" pattern="^[0-9a-za-z]+$" />yen
                      <button type="button" className="additionbtn" onClick={priceEnDel} value={price.id}><i className="fas fa-minus"></i></button>
                    </div>
                    )) }
                    {priceEn.length < 5 &&
                    <button type="button" className="additionbtn" onClick={priceEnAdd}><i className="fas fa-plus"></i></button>
                    }
                    <br />
                    <span>入力例：（席種名）S　（値段）2,000<br />入力欄は５つまで増やせます。それ以上の席種は「その他」欄にご入力ください。</span>
                  </>}
                </td>
              </tr>
              <tr>
                <th>ホームページURL</th>
                <td>
                  <input type="text" {...url_enProps} /><br />
                  <span>英語対応のURLをご入力ください</span>
                </td>
              </tr>
              <tr>
                <th>問合せ先</th>
                <td><input type="text" {...contact_enProps} size="80" /><br />
                <span>（団体名、もしくは個人名（苗字のみ））</span></td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>

        <p>【利用目的】・【確認事項】をスクロールして最後までご確認ください。次に「確認、了承しました。」にチェックを入れてください。「確認画面へ進む」がクリックできるようになります。</p>
        <div className="Terms">
          <strong>【利用目的】</strong>
          <ol className="num">
            <li>横浜みなとみらいホール 紙面版とウェブサイト版コンサートカレンダーに公演情報を掲載します。</li>
            <li>お客様からお問合せがあった場合に情報提供します。</li>
            <li>公演当日のホールスタッフ配置時の参考資料として使用します。</li>
            </ol><br />
          <strong>【留意事項】</strong><br />
          <ol className="num">
            <li>提出前に必ず主催者で記載に誤りがないことを確認してください。<br />当館は主催者が入力した内容により発生した主催者の損害、および主催者が第三者に与えた損害に対し、一切の責任を負いません。</li>
            <li>楽器や作曲者、曲目の表記など、いただいた情報はウェブサイト全体の統一を図るために編集させていただく場合がございます。あらかじめご了承ください。</li>
            </ol>
            <div id="end" ref={ref}></div>
        </div>

        <p className="Terms__check"><label><input type="checkbox" checked={agreeProps.val.includes("agree")} value="agree" {...agreeProps} disabled={!inView} />&nbsp;【利用目的】・【確認事項】を確認、了承しました。</label></p>

      <div className="Form__btnbox">
          <div className="Form__btn-return">
            <Link to="/concerts">戻る</Link>
          </div>
          <div className="Form__btn-confirm">
            <input value="確認画面へ進む" type="submit" id="next" disabled={!agreeProps.val.includes("agree") || hasError} onClick={submit} />
          </div>
        </div>

      </>
    : '' }
    </>
 );
}
