import { React, useState, useEffect } from "react";
import { Outlet, useParams,Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput,useRadio } from "./hooks";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { fontSize } from "@mui/system";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

const Input = styled('input')({
  display: 'none',
});

export default function ConcertPrAddConfirm() {
  let {id} = useParams();
  if(!id){
    id = 'new';
  }

  const { data,dataMy,myInfo,dataConcert,dataConcertPr,setDataConcertPr,dataPlayerParts,getConcert,getConcertPlayerParts,postConcertImage } = useMyData();

  const [tab_itemProps] = useRadio("jp"); // タブ

  const [sdateProps] = useInput(dataConcertPr.sdate||""); // 公演日・曜日
  const [venueProps] = useInput(dataConcertPr.venue || ""); // 会場

  const [publish_forProps] = useRadio(dataConcertPr.publish_for||"2"); // 掲載希望
  const [publish_paperProps] = useRadio(dataConcertPr.publish_paper||""); // 紙面への掲載希望
  const [open_timeProps] = useInput(dataConcertPr.open_time||""); // 開場時間
  const [start_timeProps] = useInput(dataConcertPr.start_time||""); // 開演時間
  const [end_timeProps] = useInput(dataConcertPr.end_time||""); // 終演時間
  const [title_jaProps] = useInput(dataConcertPr.title_ja||""); // 公演名(日本語)
  const [title_enProps] = useInput(dataConcertPr.title_en||""); // 公演名(英語)
  const [ticket_typeProps] = useRadio(dataConcertPr.ticket_type||""); // チケット種別
  const [ticket_type_text_jaProps] = useInput(dataConcertPr.ticket_type_text_ja||""); // チケット種別（自由入力）(日本語)
  const [ticket_type_text_enProps] = useInput(dataConcertPr.ticket_type_text_en||""); // チケット種別（自由入力）(英語)
  const [chargeProps] = useRadio(dataConcertPr.charge||""); // 入場料金
  const [ticket_saleProps] = useRadio(dataConcertPr.ticket_sale||""); // チケット販売方法
  const [ticket_sale_text_jaProps] = useInput(dataConcertPr.ticket_sale_text_ja||""); // チケット販売方法（自由入力）(日本語)
  const [ticket_sale_text_enProps] = useInput(dataConcertPr.ticket_sale_text_en||""); // チケット販売方法（自由入力）(英語)
  const [ticket_sale_dateProps] = useInput(dataConcertPr.ticket_sale_date||""); // チケット販売・受付開始日(一般)
  const [children_enterProps] = useRadio(dataConcertPr.children_enter||""); // 未就学児の入場
  const [url_jaProps] = useInput(dataConcertPr.url_ja||""); // ホームページURL(日本語)
  const [url_enProps] = useInput(dataConcertPr.url_en||""); // ホームページURL(英語)
  const [commentProps] = useInput(dataConcertPr.comment||""); // その他（事前申込制や整理番号を配布する場合、入力しきれなかった席種情報がある場合は、こちらの欄にご入力ください。（最大100文字））
  const [contact_jaProps] = useInput(dataConcertPr.contact_ja||""); // 問合せ先(日本語)
  const [contact_enProps] = useInput(dataConcertPr.contact_en||""); // 問合せ先(英語)
  const [telaProps] = useInput(dataConcertPr.tela||"","number"); // 問合せ先電話番号a
  const [telbProps] = useInput(dataConcertPr.telb||"","number"); // 問合せ先電話番号b
  const [telcProps] = useInput(dataConcertPr.telc||"","number"); // 問合せ先電話番号c
  const [emailProps] = useInput(dataConcertPr.email||""); // 問合せ先メールアドレス
  const [email1Props] = useInput(dataConcertPr.email1||""); // 問合せ先メールアドレス
  const [email2Props] = useInput(dataConcertPr.email2||""); // 問合せ先メールアドレス

  const [n_nameProps,,setN_nameProps] = useInput(dataConcertPr.n_name||""); // 入力者団体名
  const [n_l_nameProps,,setN_l_nameProps] = useInput(dataConcertPr.n_l_name||""); // 入力者代表者姓
  const [n_f_nameProps,,setN_f_nameProps] = useInput(dataConcertPr.n_f_name||""); // 入力者代表者名
  const [n_emailProps,,setN_emailProps] = useInput(dataConcertPr.n_email||""); // 入力者メールアドレス
  const [n_email1Props,,setN_email1Props] = useInput(dataConcertPr.n_email1||""); // 入力者メールアドレス
  const [n_email2Props,,setN_email2Props] = useInput(dataConcertPr.n_email2||""); // 入力者メールアドレス
  const [n_telaProps,,setN_telaProps] = useInput(dataConcertPr.n_tela||"","number"); // 入力者電話番号a
  const [n_telbProps,,setN_telbProps] = useInput(dataConcertPr.n_telb||"","number"); // 入力者電話番号b
  const [n_telcProps,,setN_telcProps] = useInput(dataConcertPr.n_telc||"","number"); // 入力者電話番号c


  const [playerJa, setPlayerJa] = useState(dataConcertPr.playerJa||[]);
  const [playerEn, setPlayerEn] = useState(dataConcertPr.playerEn||[]);
  const [partsJa, setPartsJa] = useState(dataConcertPr.partsJa||[]);
  const [partsEn, setPartsEn] = useState(dataConcertPr.partsEn||[]);
  const [programJa, setProgramJa] = useState(dataConcertPr.programJa||[]);
  const [programEn, setProgramEn] = useState(dataConcertPr.programEn||[]);
  const [priceJa, setPriceJa] = useState(dataConcertPr.priceJa||[]);
  const [priceEn, setPriceEn] = useState(dataConcertPr.priceEn||[]);

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [mailCheck, setMailCheck] = useState(true); // メールアドレスチェック
  const [n_mailCheck, setNMailCheck] = useState(true); // 入力者メールアドレスチェック

  const [selectedFile, setSelectedFile] = useState(dataConcertPr.selectedFile); // 画像登録用
  const [selectedFile2, setSelectedFile2] = useState(dataConcertPr.selectedFile2); // 画像登録用
	const [isFilePicked, setIsFilePicked] = useState(false); // 画像登録用
  const [isFileSizeTooBig, setIsFileSizeTooBig] = useState(false); // 画像登録用
  const [uploadedFile, setUploadedFile] = useState(dataConcertPr.keyimage); // 画像登録用
  const [uploadedFile2, setUploadedFile2] = useState(dataConcertPr.keyimage2); // 画像登録用

  useEffect(()=>{
    getConcert(id);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },[data]);


  const convertDate = (date) => {
    const dow=['日','月','火','水','木','金','土'];
    const d = new Date(date);
    return d.getFullYear() +'年' + (d.getMonth() +1) +'月' + d.getDate() +'日' + '(' + dow[d.getDay()] + ')';
  }

  // 確認画面へ
  const submit = (e) => {
    e.preventDefault();

    if(e.target.id==='back') {
      setDataConcertPr( {
        ...dataConcertPr,
        mode: "back"
      });
    }

    if(e.target.id==='next') {
      setDataConcertPr( {
        ...dataConcertPr,
        mode: "send"
      });
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
    { dataConcert.id || id=='new' ?
      <>
        <h1 className="pagetopLabel">公演情報登録(確認)</h1>

        <div className="tabs">
          <input id="jp" type="radio" name="tab_item" {...tab_itemProps} value="jp" checked={tab_itemProps.val=="jp"} /><label className="tab_item" htmlFor="jp">公演情報（和文）</label>
          <input id="eng" type="radio" name="tab_item" {...tab_itemProps} value="eng" checked={tab_itemProps.val=="eng"} /><label className="tab_item" htmlFor="eng">公演情報（英文）</label>

          <div className="tab_content" id="jp_content">
            <table className="Form" summary="#">
              <tbody>
              <tr>
                <th>掲載希望<span>※必須</span></th>
                <td>{publish_forProps.val==2 &&
                      <>公式ウェブサイト及び紙面版コンサートカレンダーへの掲載を希望する<br />
                      <span>※紙面掲載の場合は、以下の締切(公演日の3～5か月前の15日)迄に情報を提出してください。</span>
                      <ul className="attentionlist"><li>4・5・6月公演：12月15日まで</li>
                        <li>7・8・9月公演：3月15日まで</li>
                        <li>10・11・12月公演：6月15日まで</li>
                        <li>1・2・3月公演：9月15日まで</li>
                      </ul>
                      </>
                    }
                    {publish_forProps.val==1 && <>公式ウェブサイトのみの掲載を希望する</>}
                    {publish_forProps.val==0 && <>掲載を希望しない</>}
                  </td>
              </tr>
              <tr>
                <th>公演日・曜日</th>
                { id==='new' ?
                <td>{convertDate(sdateProps.value)}</td>
                : 
                <td>{ convertDate(dataConcert.sdate) }</td>
                }
              </tr>
              <tr>
                <th>開場時間/開演時間/終演時間 <span>※必須</span></th>
                <td>
                  開場 {open_timeProps.value}
                  &nbsp;／&nbsp;
                  開演 {start_timeProps.value}
                  &nbsp;／&nbsp;
                  終演 {end_timeProps.value}
                </td>
              </tr>
              <tr>
                <th>会場</th>
                { id==='new' ?
                <td>
                  {venueProps.value==10 ? '大ホール' : ''}
                  {venueProps.value==20 ? '小ホール' : ''}
                  {venueProps.value==30 ? 'レセプションルーム' : ''}
                  {venueProps.value==99 ? 'その他' : ''}
                </td>
                :
                <td>
                  {dataConcert.venue==10 ? '大ホール' : ''}
                  {dataConcert.venue==20 ? '小ホール' : ''}
                  {dataConcert.venue==30 ? 'レセプションルーム' : ''}
                  {dataConcert.venue==99 ? 'その他' : ''}
                </td>
                }
              </tr>
              <tr>
                <th>公演名<span>※必須</span></th>
                <td>{title_jaProps.value}</td>
              </tr>
              <tr>
                <th>主催/協力/貸館</th>
                { id==='new' ?
                <td>
                貸館公演
                </td>
                :
                <td>
                {dataConcert.type==0 ? '主催公演' : ''}
                {dataConcert.type==1 ? '協力公演' : ''}
                {dataConcert.type==2 ? '貸館公演' : ''}
                </td>
                }
              </tr>
              <tr>
                <th>出演者/演奏団体</th>
                <td>
                  { playerJa.map((player) => (
                    <div key={player.id}>
                      {player.name}
                      &nbsp;／&nbsp;
                      {player.part}
                    </div>
                  ))}
                </td>
              </tr>
              <tr>
                <th>作曲者/曲目</th>
                <td>
                  { programJa.map((program) => (
                    <div key={program.id}>
                      {program.author}
                      &nbsp;／&nbsp;
                      {program.title}
                    </div>
                  )) }
                </td>
              </tr>
              <tr>
                <th>チケット種別<span>※必須</span></th>
                <td>
                  {ticket_typeProps.val==10 && <>全席指定</>}
                  {ticket_typeProps.val==20 && <>全席自由</>}
                  {ticket_typeProps.val==30 && <>当日座席指定</>}
                  {ticket_typeProps.val==40 && <>関係者のみ</>}
                  {ticket_typeProps.val==99 && <>その他 {ticket_type_text_jaProps.value}</>}
                </td>
              </tr>
              <tr>
                <th>入場料金<span>※必須</span></th>
                <td>
                {chargeProps.val==="1" && <>有料</>}
                {chargeProps.val==="0" && <>無料</>}
                  <br />
                  { priceJa.map((price) => (
                  <div key={price.id}>
                    {price.name}
                      &nbsp;／&nbsp;
                    {Number(price.price).toLocaleString() }円
                  </div>
                  )) }
                </td>
              </tr>
              <tr>
                <th>チケット販売方法</th>
                <td>
                  {ticket_saleProps.val==10 && <>チケットぴあに販売委託（ホールチケットセンター（窓口･電話･WEB）を含む）<br /><span>※チケットぴあに委託された場合、ホールチケットセンターでも取扱いとなります。</span></>}
                  {ticket_saleProps.val==20 && <>ホールチケットセンター委託販売（ホールチケットセンター窓口のみ）</>}
                  {ticket_saleProps.val==30 && <>主催者のみの販売、またはチケットぴあ以外のチケット会社に委託</>}
                  {ticket_saleProps.val==99 && <>その他 {ticket_sale_text_jaProps.value}</>}
                </td>
              </tr>
              <tr>
                <th>チケット販売・受付開始日</th>
                <td>{ticket_sale_dateProps.value && convertDate(ticket_sale_dateProps.value)}</td>
              </tr>
              <tr>
                <th>未就学児の入場</th>
                <td>
                  {children_enterProps.val==30 && <>未就学児入場不可</>}
                  {children_enterProps.val==10 && <>未就学児入場可【チケット要】</>}
                  {children_enterProps.val==20 && <>未就学児入場可【チケット不要】</>}
                </td>
              </tr>
              <tr>
                <th>ホームページURL</th>
                <td>
                  {url_jaProps.value}
                </td>
              </tr>
              { (dataConcert.type==0 || dataConcert.type==1) &&
              <>
              <tr>
                <th>画像･イメージ</th>
                <td>
                  {uploadedFile && selectedFile && selectedFile.name.match(/\.(jpeg|jpg|png|gif)$/i) ?
                  <Card sx={{ maxWidth: 345 }}>
                     <CardMedia
                        component="img"
                        image={process.env.REACT_APP_API_ENDPOINT + 'my/concert/' + id + '/image/' + uploadedFile}
                      />
                  </Card>
                  : <br />}
                  {selectedFile && <>
                    <span>{selectedFile.name} </span>
                    <span>({ Math.ceil(selectedFile.size / 1000) } kb)</span>
                  </>}
                </td>
              </tr>
              <tr>
                <th>チラシPDF</th>
                <td>
                  {uploadedFile2 && selectedFile2 && selectedFile2.name.match(/\.(jpeg|jpg|png|gif)$/i) ?
                  <Card sx={{ maxWidth: 345 }}>
                     <CardMedia
                        component="img"
                        image={process.env.REACT_APP_API_ENDPOINT + 'my/concert/' + id + '/image/' + uploadedFile2}
                      />
                  </Card>
                  : <br />}
                  {selectedFile2 && <>
                    <span>{selectedFile2.name} </span>
                    <span>({ Math.ceil(selectedFile2.size / 1000) } kb)</span>
                  </>}
                </td>
              </tr>
              </>
              }
              <tr>
                <th>その他</th>
                <td>
                  <pre>{commentProps.value}</pre>
                </td>
              </tr>
              <tr>
                <th>「問合せ先名称」の紙面版コンサートカレンダーへの掲載希望<span>※必須</span></th>
                <td>
                  {publish_paperProps.val==1 && <>希望する</>}
                  {publish_paperProps.val==0 && <>希望しない</>}
                </td>
              </tr>
              <tr>
                <th>問合せ先<span>※必須</span></th>
                <td>{contact_jaProps.value}</td>
              </tr>
              <tr>
                <th>問合せ先電話番号</th>
                <td>{telaProps.value && <>{telaProps.value}ー{telbProps.value}ー{telcProps.value}</>}</td>
              </tr>
              <tr>
                <th>問合せ先メールアドレス</th>
                <td>
                    {emailProps.value}
                </td>
              </tr>
            </tbody>
            </table>

            <h6>入力者連絡先</h6>
            <table className="Form" summary="#">
              <tbody><tr>
                <th>団体名 <span>※必須</span></th>
                <td>{n_nameProps.value}</td>
              </tr>
              <tr>
                <th>担当者名 <span>※必須</span></th>
                <td>
                  {n_l_nameProps.value}
                  &nbsp;
                  {n_f_nameProps.value}
                </td>
              </tr>
              <tr>
                <th>メールアドレス<span>※必須</span></th>
                <td>
                    {n_emailProps.value}
                </td>
              </tr>
              <tr>
                <th>電話番号 <span>※必須</span></th>
                <td>{n_telaProps.value && <>{n_telaProps.value}ー{n_telbProps.value}ー{n_telcProps.value}</>}</td>
              </tr>
            </tbody>
            </table>
          </div>

          <div className="tab_content" id="eng_content"><br />
            <p>ウェブサイト公演カレンダー（英語版）に掲載希望の場合は入力してください。</p>
            <table className="Form" summary="#">
              <tbody>
              <tr>
                <th>公演名</th>
                <td>{title_enProps.value}</td>
              </tr>
              <tr>
                <th>出演者/演奏団体</th>
                <td>
                  { playerEn.map((player) => (
                    <div key={player.id}>
                      {player.name}
                      &nbsp;／&nbsp;
                      {player.part}
                    </div>
                  ))}
                </td>
              </tr>
              <tr>
                <th>作曲者/曲目</th>
                <td>
                  { programEn.map((program) => (
                    <div key={program.id}>
                      {program.author}
                      &nbsp;／&nbsp;
                      {program.title}
                    </div>
                  )) }
                </td>
              </tr>
              <tr>
                <th>チケット種別</th>
                <td>
                  {ticket_typeProps.val==10 && <>全席指定</>}
                  {ticket_typeProps.val==20 && <>全席自由</>}
                  {ticket_typeProps.val==30 && <>当日座席指定</>}
                  {ticket_typeProps.val==40 && <>関係者のみ</>}
                  {ticket_typeProps.val==99 && <>その他 {ticket_type_text_enProps.value}</>}
                </td>
              </tr>
              <tr>
                <th>入場料金</th>
                <td>
                  {chargeProps.val==="1" && <>有料</>}
                  {chargeProps.val==="0" && <>無料</>}
                  <br />
                  { priceEn.map((price) => (
                  <div key={price.id}>
                    {price.name}
                      &nbsp;／&nbsp;
                    {Number(price.price).toLocaleString() }yen
                  </div>
                  )) }
                </td>
              </tr>
              <tr>
                <th>ホームページURL</th>
                <td>
                  {url_enProps.value}
                </td>
              </tr>
              <tr>
                <th>問合せ先</th>
                <td>{contact_enProps.value}</td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>

        <div className="Form__btnbox">
          <div className="Form__btn-return">
            <input value="戻る" type="submit" id="back" onClick={submit} />
          </div>
          <div className="Form__btn-confirm">
            <input value="登録" type="submit" id="next" onClick={submit} />
          </div>
        </div>

      </>
    : '' }
    </>
 );
}
